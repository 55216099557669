import React from "react"
import Heading from "@/components/Heading"
// import style from "./style.module.scss"

const TadaGovernance = () => {
  return (
    <div className="tada__block">
      <Heading id="governance">
        <strong>Governance</strong> assets
      </Heading>
      <p>
        A community-managed treasury opens up a world of infinite possibilities.
        We hope to see a variety of experimentation, including ecosystem grants
        and public goods funding, both of which can foster additional TADATek
        ecosystem growth. TADATek has set the standard for automated liquidity
        provision: it is now time to set the benchmark for responsible but
        radical, long-term aligned on-chain governance systems.
      </p>
      <p>
        By simply holding $TADA, even if staked in the governance pool. Voting
        sway and power are derived from the $TADA holdings of the participant.
        The reasoning behind this is that those holding more $TADA are more
        invested in the project. Therefore, they have a more significant
        incentive to succeed and prosper.
      </p>
      <p>In the meantime, TADA holders will have immediate ownership of:</p>
      <ul>
        <li>TADATek governance</li>
        <li>TADA community treasury</li>
        <li>The protocol fee switch</li>
      </ul>
      {/* <p>Initial governance parameters are as follows:</p>
      <ul className="mb-5">
        <li>
          1% of TADA total supply (delegated) to submit a governance proposal
        </li>
        <li>4% of TADA supply required to vote ’yes’ to reach quorum</li>
        <li>7 day voting period</li>
        <li>2 day timelock delay on execution</li>
      </ul> */}
    </div>
  )
}

export default TadaGovernance
