import React from "react"
import { Helmet } from "react-helmet"
import MainLayout from "@/layouts/Main"
import TadaPromo from "@/components/TadaPromo"
import TadaIntroducing from "@/components/TadaIntroducing"
import TadaGovernance from "@/components/TadaGovernance"

const Page = () => {
  return (
    <MainLayout>
      <Helmet title="TADA Token" />
      <TadaPromo />
      <TadaIntroducing />
      <TadaGovernance />
    </MainLayout>
  )
}

export default Page
