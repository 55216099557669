import React from "react"
import { Link } from "gatsby"
import { SVGLink } from "@/svg"
// import * as style from "./style.module.scss"

const TadaPromo = () => {
  return (
    <div className="tada__block">
      <div className="tada__promo">
        <div className="max-width-1000">
          <h1
            className="tada__promo__text"
            data-aos="fade-up"
            data-aos-delay="000"
          >
            TADA Native Token.
          </h1>
        </div>
        <p data-aos="fade-up" data-aos-delay="300">
          TADA token - is a Cardano based cryptocurrency token for a fuelling
          the TADATek platform. TADATek is a highly functional open source
          project that banks on blockchain technology’s permissionless nature to
          provide decentralized finance (DeFi) solutions and other services.
        </p>
        <p className="mb-5" data-aos="fade-up" data-aos-delay="500">
          Ticker:{" "}
          <strong className="bolder text-active">
            <span className="tada__ticker">TADA</span>
          </strong>
          <br />
          Policy ID:{" "}
          <strong className="text-active">
            <span className="text-break">
              b26593517dd1d5db7f8b16163b728e7bc773f7f083db93b9a7bef495
            </span>
          </strong>
          <br />
          Fingerprint:{" "}
          <strong className="text-active">
            <span className="text-break">
              asset1qnp3p6wh5qc5zx5clwzwy0pgpal2marz32r9gj
            </span>
          </strong>
          <br />
          More info:{" "}
          <strong className="text-active">
            <a
              href="https://cardanoscan.io/token/04c310e9d7a031411a98fb84e23c280f7eadf462"
              target="_blank"
              rel="noopener noreferrer"
            >
              View in Explorer
            </a>
          </strong>
        </p>
        <div
          className="d-block d-md-flex font-size-18"
          data-aos="fade-up"
          data-aos-delay="700"
        >
          <a
            href="https://cardanoscan.io/token/04c310e9d7a031411a98fb84e23c280f7eadf462"
            target="_blank"
            rel="noopener noreferrer"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            <span className="me-2">View in Explorer</span>
            <span className="tada__icon">
              <SVGLink />
            </span>
          </a>
          <Link
            to="/tada/tokenomics/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Tokenomics
          </Link>
          <Link
            to="/tada/distribution/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Distribution
          </Link>
          <Link
            to="/tada/governance/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Governance
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TadaPromo
